@use "@ciphr/styles" as ciphr;
@use "@angular/cdk";

@import "tippy.js/dist/tippy.css";
@import "tippy.js/themes/light.css";
@import "tippy.js/animations/scale.css";

@import "@ciphr-design-system/foundations/ciphr-light";
@import "@angular/cdk/overlay-prebuilt.css";
@import "styles/media";

@include cdk.a11y-visually-hidden();
@include ciphr.tooltip();

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  box-sizing: border-box;
}

body {
  margin: 0;
}

.title-xs {
  color: var(--interface-content-neutral-bolder, #222831);
  font: var(--chr-title-xs);
  letter-spacing: var(--chr-title-xs-letter-spacing);
}

.body-xl {
  color: var(--interface-content-neutral, #647790);
  font: var(--chr-body-xl);
  letter-spacing: var(--chr-body-xl-letter-spacing);
}

.body-l {
  color: var(--interface-content-neutral, #647790);
  font: var(--chr-body-l);
}

.body-l-bold {
  color: var(--interface-content-neutral-bolder, #222831);
  font: var(--chr-body-l-bold);
  letter-spacing: var(--chr-body-l-bold-letter-spacing);
}

.body-m {
  color: var(--interface-content-neutral, #647790);
  font: var(--chr-body-m);
}

.body-m-bold {
  color: var(--interface-content-neutral-bolder, #222831);
  font: var(--chr-body-m-bold);
  letter-spacing: var(--chr-body-m-bold-letter-spacing);
}

.admin-overlay {
  z-index: 10;
}

.sso-dialog,
.identity-provider-dialog {
  height: 100%;
  width: 100%;
  margin-top: 1.5rem;

  @include ciphr.respond-to-media(web) {
    height: auto;
    width: auto;

    margin-top: 0;
  }

  chr-modal {
    height: 100%;
    overflow: auto;
  }
}

.sso-dialog {
  @include ciphr.respond-to-media(web) {
    width: 480px;
    height: auto;
  }
}

.identity-provider-dialog {
  @include ciphr.respond-to-media(web) {
    width: 840px;
    height: auto;

    padding: 3.75rem;
  }
}

.identity-provider-dialog {
  & > {
    .cdk-dialog-container {
      height: auto;
    }
  }
}

.chr-datepicker-overlay {
  z-index: 9999;
}

.template-error-message {
  display: flex;
  align-items: center;
  gap: 4px;

  margin-top: 4px;

  color: var(--chr-interface-content-error-bold);
  font: var(--chr-body-m);

  chr-icon {
    color: var(--chr-interface-content-error);
  }
}
